<template>
  <div class="animated fadeIn">
    <b-row>
      <ResponseAlert ref="response" />
      <div class="col-sm-12 mb-2 d-flex justify-content-between align-items-center flex-column flex-md-row">
        <div class="col-md-4 col-12 px-0 order-1 order-md-0">
          <div class="input-group">
            <input
              v-model="config.search"
              type="text"
              placeholder="Cari nama customer atau kata kunci"
              class="form-control form-control-sm"
              @keyup.enter="config.page = 1; get()"
            >
            <div class="input-group-append">
              <div
                class="input-group-text"
                @click="config.page = 1; get()"
              >
                <feather-icon icon="SearchIcon" />
              </div>
            </div>
          </div>
        </div>
        <div class="order-0 order-md-1 mb-1 mb-md-0 full-width-responsive">
          <b-button
            v-if="hasPermission('export/invoice/customer')"
            v-b-modal.modal-export-invoice
            variant="secondary"
            class="btn-min-width rounded full-width-responsive"
          >
            <feather-icon
              icon="DownloadIcon"
            />
            Export Data
          </b-button>
        </div>
      </div>
      <b-col sm="12">
        <div class="table-responsive">
          <table class="table b-table">
            <thead>
              <tr>
                <th
                  v-for="(row, key) in config.headers"
                  :key="key"
                  :width="row.width"
                  :class="row.class"
                >
                  <a
                    v-if="row.value"
                    class="sort dark-font"
                    href="javascript:void(0)"
                    @click="sort(row.value)"
                  >
                    {{ row.title }}
                    <i
                      :id="row.value"
                      class="fa fa-sort"
                    />
                  </a>
                  <a
                    v-else
                    :id="row.value"
                    class="sort dark-font"
                    href="javascript:void(0)"
                  >
                    {{ row.title }}
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="config.total_data">
                <tr
                  v-for="(row, key) in config.rows"
                  :key="key"
                >
                  <td><a
                    href="#"
                    @click="getDetailInvoice(row.id)"
                  >{{ row.invoiceCode }}</a> </td>
                  <td>{{ moment(row.invoiceDate).format('DD-MM-YYYY HH:mm:ss') }}</td>
                  <td>{{ row.statusText }}</td>
                  <td>{{ row.since }}</td>
                  <td>Rp. {{ parseFloat(row.totalPayment).toLocaleString().replace(/[.,]/g, m => m === '.' ? ',' : '.') }}</td>
                </tr>
              </template>
              <tr v-if="!config.total_data">
                <td
                  :colspan="config.headers.length"
                  align="center"
                >
                  No data available.
                </td>
              </tr>
            </tbody>
            <tfoot v-if="config.total_data">
              <tr>
                <td
                  :colspan="config.headers.length"
                  class="p-0"
                >
                  <div class="d-flex justify-content-between mt-2">
                    <b-form-select
                      v-model="config.per_page"
                      :options="perPageOptions"
                      class="w-auto"
                    />
                    <b-pagination
                      v-model="config.page"
                      :total-rows="config.total_data"
                      :per-page="config.per_page"
                      @change="gotoPage"
                    />
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </b-col>
    </b-row>

    <!-- Modal Export -->
    <b-modal
      id="modal-export-invoice"
      ref="modal-export-invoice"
      centered
      title="Export Data"
      hide-footer
      no-close-on-backdrop
    >
      <form>
        <div class="animated fadeIn">
          <b-row
            class="mb-1"
            align-v="center"
          >
            <b-col sm="5">
              <label
                class="h5"
                for="date"
              >Tanggal</label>
            </b-col>
            <b-col sm="7">
              <date-range-picker
                :date-range="vars.invoiceDate"
                @update="updateDate"
              />
            </b-col>
          </b-row>
          <b-row
            class="mb-1"
            align-v="baseline"
          >
            <b-col sm="5">
              <label
                class="h5"
                for="location"
              >Lokasi</label>
            </b-col>
            <b-col sm="7">
              <v-select
                id="tag"
                v-model="models.since"
                clearable
                label="name"
                placeholder="- Pilih Lokasi -"
                :options="since"
                :reduce="options => options.name"
                @search="value => debounceSearch(value, 'regencies')"
              >
                <template #no-options="{ search, searching, loading }">
                  Tidak ditemukan hasil pencarian
                </template>
              </v-select>
            </b-col>
          </b-row>

          <div class="d-flex justify-content-center justify-content-md-end mt-2">

            <b-button
              type="button"
              variant="outline-secondary"
              class="btn-min-width rounded"
              @click="exportExcel"
            >
              Export Data
            </b-button>

          </div>
        </div>
      </form>
    </b-modal>
    <!-- Modal Detail -->
    <b-modal
      id="modal-detail-invoice"
      ref="modal-detail-invoice"
      centered
      title="Detail Invoice"
      hide-footer
    >
      <form
        @submit.prevent="save"
      >
        <div class="animated fadeIn">
          <b-row
            class="mb-1"
            align-v="baseline"
          >
            <b-col sm="5">
              <label
                class="h5"
                for="date"
              >Tanggal</label>
            </b-col>
            <b-col sm="7">
              <b-form-input
                id="date"
                :value="detail.date"
                class="w-100"
                disabled
              />
            </b-col>
          </b-row>
          <b-row
            class="mb-1"
            align-v="baseline"
          >
            <b-col sm="5">
              <label
                class="h5"
                for="location"
              >Lokasi</label>
            </b-col>
            <b-col sm="7">
              <b-form-input
                id="location"
                :value="detail.since"
                class="w-100"
                disabled
              />
            </b-col>
          </b-row>
          <b-row
            class="mb-1"
            align-v="baseline"
          >
            <b-col sm="5">
              <label
                class="h5"
                for="location"
              >Detail</label>
            </b-col>
          </b-row>
          <b-row
            class="mb-1"
            align-v="baseline"
          >
            <b-col cols="12">
              <b-row
                v-for="item, idx in detail.items"
                :key="idx"
                class="px-2"
              >
                <b-col cols="6">
                  <p>{{ item.name }}</p>
                </b-col>
                <b-col cols="6">
                  <p>Rp. {{ parseFloat(item.unitPrice || item.price).toLocaleString().replace(/[.,]/g, m => m === '.' ? ',' : '.') }}</p>
                </b-col>
              </b-row>

              <b-row class="px-2">
                <b-col cols="6">
                  <p class="font-weight-bold">
                    Total
                  </p>
                </b-col>
                <b-col cols="6">
                  <p class="font-weight-bold">
                    Rp. {{ parseFloat(detail.total).toLocaleString().replace(/[.,]/g, m => m === '.' ? ',' : '.') }}
                  </p>
                </b-col>
              </b-row>
              <b-row class="px-2">
                <b-col cols="6">
                  <p class="font-weight-bold">
                    Status
                  </p>
                </b-col>
                <b-col cols="6">
                  <p class="font-weight-bold">
                    {{ detail.statusText }}
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import { exportExcel } from '@/utils/helpers'
import vSelect from 'vue-select'
import DateRangePicker from '@/layouts/components/DateRangePicker.vue'
import {
  BRow, BCol, BPagination, BFormSelect, BButton, BModal, BFormInput,
} from 'bootstrap-vue'
// import table from '@/utils/table.js'
import api from '@/utils/api'

export default {
  name: 'SubDetailInvoice',
  components: {
    BRow,
    BCol,
    BPagination,
    BFormSelect,
    BButton,
    BModal,
    vSelect,
    DateRangePicker,
    BFormInput,
  },
  props: ['since'],
  data() {
    return {
      config: {
        uri: this.$route.meta.link,
        api: `${api.customers}/${this.$route.params.id}/invoices`,
        rows: [],
        total_data: 0,
        total_page: 0,
        per_page: 10,
        page: 1,
        orderby: 'id',
        sort: 'desc',
        search: '',
        headers: [{
          title: 'No Invoice',
          value: 'invoiceCode',
          align: 'text-left',
          width: '25%',
        }, {
          title: 'Tanggal Invoice',
          value: 'invoiceDate',
          align: 'text-left',
          width: '25%',
        }, {
          title: 'Status',
          value: 'status',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Lokasi',
          value: 'since',
          align: 'text-left',
          width: '20%',
        }, {
          title: 'Total',
          value: 'totalPayment',
          align: 'text-left',
          width: '15%',
        }],
      },
      vars: {
        invoiceDate: {
          startDate: null,
          endDate: null,
        },
      },
      models: {
        invoiceDate: '',
        since: '',
      },

      detail: {
        since: '',
        date: '',
        items: [],
        total: '',
        statusText: '',
      },
    }
  },
  computed: {
    perPageOptions() {
      return this.$store.state.app.perPageOptions
    },
    perPage() {
      return this.config.per_page
    },
  },
  watch: {
    perPage() {
      this.config.page = 1
      this.get()
    },
  },
  created() {
    const _ = this
    _.get()
  },
  methods: {
    get() {
      this.$table.get(this.config)
    },
    sort(orderby) {
      if (orderby !== '') this.$table.sort(this.config, orderby)
    },
    gotoPage(page) {
      this.$table.gotoPage(this.config, page)
    },
    debounceSearch(query, type) {
      if (query !== '') {
        clearTimeout(this.debounce)
        this.debounce = setTimeout(async () => {
          this.$emit('search', {
            type,
            query,
          })
        }, 500)
      }
    },
    exportExcel() {
      exportExcel('List Customer Invoice', `${api.customers}/${this.$route.params.id}/invoices/export`, this.models)
      this.models = {
        invoiceDate: '',
        since: '',
      }
      this.vars.invoiceDate = {
        startDate: null,
        endDate: null,
      }
      this.$bvModal.hide('modal-export-invoice')
    },
    updateDate(value) {
      this.vars.invoiceDate = value
      const { startDate, endDate } = value
      this.models.invoiceDate = `${this.moment(startDate).format('YYYY-MM-DD')},${this.moment(endDate).format('YYYY-MM-DD')}`
    },
    getDetailInvoice(id) {
      this.detail.items = []
      if (this.hasPermission('detail/invoice/customer')) {
        this.$axios.get(`${api.customers}/${id}/invoices/detail`)
          .then(res => {
            const { data } = res.data
            const invoiceIdx = this.config.rows.findIndex(inv => inv.id === id)
            if (invoiceIdx > -1) {
              this.detail.since = this.config.rows[invoiceIdx].since
              this.detail.date = this.moment(this.config.rows[invoiceIdx].invoiceDate).format('DD-MM-YYYY HH:mm:ss')
            }
            data.products.forEach(item => {
              if (item.product) {
                item.name = item.product.name
                this.detail.items.push(item)
              }
            })

            data.treatments.forEach(item => {
              if (item.treatment && item.treatment.detail) {
                item.name = item.treatment.detail.name
                this.detail.items.push(item)
              }
            })
            this.detail.total = this.detail.items.map(item => {
              const price = item.unitPrice || item.price
              return Number(price)
            }).reduce((total, amount) => total + amount, 0)
            this.detail.statusText = data.statusText
            this.$bvModal.show('modal-detail-invoice')
          })
      }
    },
  },
}
</script>
