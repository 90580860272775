<template>
  <div class="animated fadeIn">
    <b-row>
      <ResponseAlert ref="response" />
      <div class="col-sm-12 mb-2 d-flex justify-content-between align-items-center flex-column flex-md-row">
        <div class="col-md-4 col-12 px-0 order-1 order-md-0">
          <div class="input-group">
            <input
              v-model="config.search"
              type="text"
              placeholder="Cari nama customer atau kata kunci"
              class="form-control form-control-sm"
              @keyup.enter="config.page = 1; get()"
            >
            <div class="input-group-append">
              <div
                class="input-group-text"
                @click="config.page = 1; get()"
              >
                <feather-icon icon="SearchIcon" />
              </div>
            </div>
          </div>
        </div>
        <div class="order-0 order-md-1 mb-1 mb-md-0 full-width-responsive">
          <b-button
            v-if="hasPermission('export/agenda/customer')"
            v-b-modal.modal-export-agenda
            variant="secondary"
            class="btn-min-width rounded full-width-responsive"
          >
            <feather-icon
              icon="DownloadIcon"
            />
            Export Data
          </b-button>
        </div>
      </div>
      <b-col sm="12">
        <div class="table-responsive">
          <table class="table b-table">
            <thead>
              <tr>
                <th
                  v-for="(row, key) in config.headers"
                  :key="key"
                  :width="row.width"
                  :class="row.class"
                >
                  <a
                    v-if="row.value"
                    class="sort dark-font"
                    href="javascript:void(0)"
                    @click="sort(row.value)"
                  >
                    {{ row.title }}
                    <i
                      :id="row.value"
                      class="fa fa-sort"
                    />
                  </a>
                  <a
                    v-else
                    :id="row.value"
                    class="sort dark-font"
                    href="javascript:void(0)"
                  >
                    {{ row.title }}
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="config.total_data">
                <tr
                  v-for="(row, key) in config.rows"
                  :key="key"
                >
                  <td>{{ row.noBooking }}</td>
                  <td>{{ moment(row.startDate).format('DD-MM-YYYY HH:mm:ss') }}</td>
                  <td>{{ row.treatments }}</td>
                  <td>{{ row.staff }}</td>
                  <td>{{ row.since }}</td>
                  <td>{{ row.status }}</td>
                </tr>
              </template>
              <tr v-if="!config.total_data">
                <td
                  :colspan="config.headers.length"
                  align="center"
                >
                  No data available.
                </td>
              </tr>
            </tbody>
            <tfoot v-if="config.total_data">
              <tr>
                <td
                  :colspan="config.headers.length"
                  class="p-0"
                >
                  <div class="d-flex justify-content-between mt-2">
                    <b-form-select
                      v-model="config.per_page"
                      :options="perPageOptions"
                      class="w-auto"
                    />
                    <b-pagination
                      v-model="config.page"
                      :total-rows="config.total_data"
                      :per-page="config.per_page"
                      @change="gotoPage"
                    />
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </b-col>
    </b-row>

    <!-- Modal Advance Search -->
    <b-modal
      id="modal-export-agenda"
      ref="modal-export-agenda"
      centered
      title="Export Data"
      hide-footer
      no-close-on-backdrop
    >
      <form>
        <div class="animated fadeIn">
          <b-row
            class="mb-1"
            align-v="center"
          >
            <b-col sm="5">
              <label
                class="h5"
                for="date"
              >Tanggal</label>
            </b-col>
            <b-col sm="7">
              <date-range-picker
                :date-range="vars.startDate"
                @update="updateDate"
              />
            </b-col>
          </b-row>
          <b-row
            class="mb-1"
            align-v="baseline"
          >
            <b-col sm="5">
              <label
                class="h5"
                for="location"
              >Lokasi</label>
            </b-col>
            <b-col sm="7">
              <v-select
                id="tag"
                v-model="models.since"
                clearable
                label="name"
                placeholder="- Pilih Lokasi -"
                :options="since"
                :reduce="options => options.name"
                @search="value => debounceSearch(value, 'regencies')"
              >
                <template #no-options="{ search, searching, loading }">
                  Tidak ditemukan hasil pencarian
                </template>
              </v-select>
            </b-col>
          </b-row>
          <b-row
            class="mb-1"
            align-v="baseline"
          >
            <b-col sm="5">
              <label
                class="h5"
                for="treatment"
              >Treatment</label>
            </b-col>
            <b-col sm="7">
              <v-select
                id="tag"
                v-model="models.treatments"
                clearable
                label="name"
                placeholder="- Pilih Treatment -"
                :options="treatments"
                :reduce="options => options.id"
                @search="value => debounceSearch(value, 'treatments')"
              >
                <template #no-options="{ search, searching, loading }">
                  Tidak ditemukan hasil pencarian
                </template>
              </v-select>
            </b-col>
          </b-row>
          <b-row
            class="mb-1"
            align-v="baseline"
          >
            <b-col sm="5">
              <label
                class="h5"
                for="status"
              >Status</label>
            </b-col>
            <b-col sm="7">
              <b-form-select
                id="status"
                v-model="models.status"
                class="w-100"
                :options="masters.statuses"
              />
            </b-col>
          </b-row>
          <div class="d-flex justify-content-center justify-content-md-end mt-2">

            <b-button
              type="button"
              variant="outline-secondary"
              class="btn-min-width rounded"
              @click="exportExcel"
            >
              Export Data
            </b-button>

          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import DateRangePicker from '@/layouts/components/DateRangePicker.vue'
import {
  BRow, BCol, BPagination, BFormSelect, BButton, BModal,
} from 'bootstrap-vue'
// import table from '@/utils/table.js'
import api from '@/utils/api'
import { exportExcel } from '@/utils/helpers'

export default {
  components: {
    BRow,
    BCol,
    BPagination,
    BFormSelect,
    BButton,
    BModal,
    vSelect,
    DateRangePicker,
  },
  props: ['since', 'treatments'],
  data() {
    return {
      config: {
        uri: this.$route.meta.link,
        api: `${api.customers}/${this.$route.params.id}/bookings`,
        rows: [],
        total_data: 0,
        total_page: 0,
        per_page: 10,
        page: 1,
        orderby: 'id',
        sort: 'desc',
        search: '',
        headers: [{
          title: 'No Booking',
          value: 'noBooking',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Tanggal Booking',
          value: 'startDate',
          align: 'text-left',
          width: '20%',
        }, {
          title: 'Treatment',
          value: 'treatments',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Staff',
          value: 'staff',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Lokasi',
          value: 'since',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Status DP',
          value: 'statusDownpayment',
          align: 'text-left',
          width: '15%',
        }],
      },
      masters: {
        statuses: [
          {
            text: '- Pilih Status',
            value: null,
          },
          {
            text: 'Menunggu Pemilihan Staff',
            value: 0,
          },
          {
            text: 'Sedang Dilayani',
            value: 1,
          },
          {
            text: 'Selesai',
            value: 2,
          },
          {
            text: 'Menunggu Waktu Layanan',
            value: -1,
          },
          {
            text: 'Menunggu Pembayaran',
            value: -2,
          },
        ],
      },
      vars: {
        startDate: {
          startDate: null,
          endDate: null,
        },
      },
      models: {
        startDate: '',
        since: '',
        status: null,
        treatments: '',
      },
    }
  },
  computed: {
    perPageOptions() {
      return this.$store.state.app.perPageOptions
    },
    perPage() {
      return this.config.per_page
    },
  },
  watch: {
    perPage() {
      this.config.page = 1
      this.get()
    },
  },
  created() {
    const _ = this
    _.get()
  },
  methods: {
    get() {
      this.$table.get(this.config)
    },
    sort(orderby) {
      if (orderby !== '') this.$table.sort(this.config, orderby)
    },
    gotoPage(page) {
      this.$table.gotoPage(this.config, page)
    },
    debounceSearch(query, type) {
      if (query !== '') {
        clearTimeout(this.debounce)
        this.debounce = setTimeout(async () => {
          this.$emit('search', {
            type,
            query,
          })
        }, 500)
      }
    },
    exportExcel() {
      exportExcel('List Customer Agenda', `${api.customers}/${this.$route.params.id}/bookings/export`, this.models)
      this.models = {
        startDate: '',
        since: '',
        status: null,
        treatments: '',
      }
      this.vars.startDate = {
        startDate: null,
        endDate: null,
      }
      this.$bvModal.hide('modal-export-agenda')
    },
    updateDate(value) {
      this.vars.startDate = value
      const { startDate, endDate } = value
      this.models.startDate = `${this.moment(startDate).format('YYYY-MM-DD')},${this.moment(endDate).format('YYYY-MM-DD')}`
    },
  },
}
</script>
