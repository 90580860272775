<template>
  <div class="animated fadeIn">
    <b-row>
      <ResponseAlert ref="response" />
      <div class="col-sm-12 mb-2 d-flex justify-content-between align-items-center flex-column flex-md-row">
        <div class="col-md-4 col-12 px-0 order-1 order-md-0">
          <div class="input-group">
            <input
              v-model="config.search"
              type="text"
              placeholder="Cari nama customer atau kata kunci"
              class="form-control form-control-sm"
              @keyup.enter="config.page = 1; get()"
            >
            <div class="input-group-append">
              <div
                class="input-group-text"
                @click="config.page = 1; get()"
              >
                <feather-icon icon="SearchIcon" />
              </div>
            </div>
          </div>
        </div>
        <div class="order-0 order-md-1 mb-1 mb-md-0 text-center">
          <b-button
            v-if="hasPermission('export/medical-record/cutomer')"
            v-b-modal.modal-export-medrec
            variant="secondary"
            class="btn-min-width rounded full-width-responsive"
          >
            <feather-icon
              icon="DownloadIcon"
            />
            Export Data
          </b-button>
        </div>
      </div>
      <b-col sm="12">
        <div class="table-responsive">
          <table class="table b-table">
            <thead>
              <tr>
                <th
                  v-for="(row, key) in config.headers"
                  :key="key"
                  :width="row.width"
                  :class="row.align"
                >
                  <a
                    v-if="row.value"
                    class="sort dark-font"
                    href="javascript:void(0)"
                    @click="sort(row.value)"
                  >
                    {{ row.title }}
                    <i
                      :id="row.value"
                      class="fa fa-sort"
                    />
                  </a>
                  <a
                    v-else
                    :id="row.value"
                    class="sort dark-font"
                    href="javascript:void(0)"
                  >
                    {{ row.title }}
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="config.total_data">
                <tr
                  v-for="(row, key) in config.rows"
                  :key="key"
                >
                  <td>{{ moment(row.startDate).format('DD-MM-YYYY HH:mm:ss') }}</td>
                  <td>{{ row.treatments }}</td>
                  <td>{{ row.staff }}</td>
                  <td>{{ row.since }}</td>
                  <td align="center">
                    <div class="d-flex justify-content-around">
                      <custom-button
                        type="button"
                        permission="detail/medical-record/customer"
                        title="Detail"
                        class-name="btn-action bg-transparent border-0 text-primary p-0"
                        @click="getDetailMedhis(row.id, 'Detail')"
                      >
                        <feather-icon icon="EyeIcon" />
                      </custom-button>
                      <custom-button
                        type="button"
                        permission="edit/medical-record/customer"
                        title="Edit"
                        class-name="btn-action bg-transparent border-0 text-primary p-0"
                        @click="getDetailMedhis(row.id, 'Edit')"
                      >
                        <feather-icon icon="EditIcon" />
                      </custom-button>
                    </div>
                  </td>
                </tr>
              </template>
              <tr v-if="!config.total_data">
                <td
                  :colspan="config.headers.length"
                  align="center"
                >
                  No data available.
                </td>
              </tr>
            </tbody>
            <tfoot v-if="config.total_data">
              <tr>
                <td
                  :colspan="config.headers.length"
                  class="p-0"
                >
                  <div class="d-flex justify-content-between mt-2">
                    <b-form-select
                      v-model="config.per_page"
                      :options="perPageOptions"
                      class="w-auto"
                    />
                    <b-pagination
                      v-model="config.page"
                      :total-rows="config.total_data"
                      :per-page="config.per_page"
                      @change="gotoPage"
                    />
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </b-col>
    </b-row>

    <!-- Modal Advance Search -->
    <b-modal
      id="modal-export-medrec"
      ref="modal-export-medrec"
      centered
      title="Export Data"
      hide-footer
      no-close-on-backdrop
    >
      <form @submit.prevent="exportExcel">
        <div class="animated fadeIn">
          <b-row
            class="mb-1"
            align-v="center"
          >
            <b-col sm="5">
              <label
                class="h5"
                for="date"
              >Tanggal</label>
            </b-col>
            <b-col sm="7">
              <date-range-picker
                :date-range="vars.startDate"
                @update="updateDate"
              />
            </b-col>
          </b-row>
          <b-row
            class="mb-1"
            align-v="baseline"
          >
            <b-col sm="5">
              <label
                class="h5"
                for="treatment"
              >Treatment</label>
            </b-col>
            <b-col sm="7">
              <v-select
                id="treatment"
                v-model="models.treatments"
                clearable
                label="name"
                placeholder="- Pilih Treatment -"
                :options="treatments"
                :reduce="options => options.id"
                @search="value => debounceSearch(value, 'treatments')"
              >
                <template #no-options="{ search, searching, loading }">
                  Tidak ditemukan hasil pencarian
                </template>
              </v-select>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-center justify-content-md-end mt-2">

            <b-button
              type="submit"
              variant="outline-secondary"
              class="btn-min-width rounded"
            >
              Export Data
            </b-button>

          </div>
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modal-medrec"
      ref="modal-medrec"
      centered
      :title="`${medrecModalTitle} Medical Record`"
      hide-footer
    >
      <form>
        <div class="animated fadeIn">
          <b-row
            v-if="isDetailModal"
            class="mb-1"
            align-v="baseline"
          >
            <b-col sm="5">
              <label
                class="h5"
                for="date"
              >Tanggal</label>
            </b-col>
            <b-col sm="7">
              <b-form-input
                v-model="detail.createdAt"
                disabled
              />
            </b-col>
          </b-row>
          <b-row
            v-if="isDetailModal"
            class="mb-1"
            align-v="baseline"
          >
            <b-col sm="5">
              <label
                class="h5"
                for="status"
              >Treatment</label>
            </b-col>
            <b-col sm="7">
              <b-form-input
                v-model="detail.treatments"
                disabled
              />

            </b-col>
          </b-row>
          <b-row
            class="mb-1"
            align-v="baseline"
          >
            <b-col cols="12">
              <label
                class="h5"
                for="status"
              >Keluhan</label>
              <b-form-textarea
                v-model="detail.description"
                :disabled="isDetailModal"
              />
            </b-col>
          </b-row>
          <b-row
            class="mb-1"
            align-v="baseline"
          >
            <b-col cols="12">
              <label
                class="h5"
                for="status"
              >Rekomendasi Treatment/Produk</label>
              <b-form-textarea
                v-model="detail.recomendation"
                :disabled="isDetailModal"
              />
            </b-col>
          </b-row>
          <b-row
            class="mb-1"
            align-v="baseline"
          >
            <b-col cols="12">
              <label
                class="h5"
                for="status"
              >Catatan</label>
              <b-form-textarea
                v-model="detail.notes"
                :disabled="isDetailModal"
              />
            </b-col>
          </b-row>
          <div
            v-if="!isDetailModal"
            class="d-flex justify-content-center mt-2"
          >
            <b-button
              variant="primary"
              class="btn-min-width rounded px-4"
              @click="save(detail.id)"
            >
              Simpan
            </b-button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import { exportExcel } from '@/utils/helpers'
import vSelect from 'vue-select'
import DateRangePicker from '@/layouts/components/DateRangePicker.vue'
import {
  BRow, BCol, BPagination, BFormSelect, BButton, BModal, BFormTextarea, BFormInput,
} from 'bootstrap-vue'
import api from '@/utils/api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ListAdmin',
  components: {
    BRow,
    BCol,
    BPagination,
    BFormSelect,
    BButton,
    BModal,
    BFormInput,
    BFormTextarea,
    DateRangePicker,
    vSelect,
  },
  props: ['treatments'],
  data() {
    return {
      config: {
        uri: this.$route.meta.link,
        api: `${api.customers}/${this.$route.params.id}/medical-histories`,
        rows: [],
        total_data: 0,
        total_page: 0,
        per_page: 10,
        page: 1,
        orderby: 'id',
        sort: 'desc',
        search: '',
        headers: [{
          title: 'Tanggal Record',
          value: 'startDate',
          align: 'text-left',
          width: '25%',
        }, {
          title: 'Treatment',
          value: 'treatments',
          align: 'text-left',
          width: '25%',
        }, {
          title: 'Staff',
          value: 'staff',
          align: 'text-left',
          width: '20%',
        }, {
          title: 'Lokasi',
          value: 'since',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Aksi',
          value: '',
          align: 'text-center',
          width: '15%',
        }],
      },
      vars: {
        startDate: {
          startDate: null,
          endDate: null,
        },
        medrec: {
          modalTitle: '',
        },
      },
      models: {
        startDate: '',
        treatments: '',
      },

      detail: {
        id: '',
        description: '',
        recomendation: '',
        notes: '',
        createdAt: '',
        treatments: '',
      },
    }
  },
  computed: {
    perPageOptions() {
      return this.$store.state.app.perPageOptions
    },
    perPage() {
      return this.config.per_page
    },
    medrecModalTitle() {
      return this.vars.medrec.modalTitle
    },
    isDetailModal() {
      return this.medrecModalTitle === 'Detail'
    },
  },
  watch: {
    perPage() {
      this.config.page = 1
      this.get()
    },
  },
  created() {
    const _ = this
    _.get()
  },
  methods: {
    get() {
      this.$table.get(this.config)
    },
    sort(orderby) {
      if (orderby !== '') this.$table.sort(this.config, orderby)
    },
    gotoPage(page) {
      this.$table.gotoPage(this.config, page)
    },
    getDetailMedhis(id, type) {
      this.vars.medrec.modalTitle = type
      this.$axios.get(`${api.customers}/${id}/medical-histories/detail`)
        .then(res => {
          const { data } = res.data
          this.detail.id = id
          this.detail.description = data.description
          this.detail.recomendation = data.recomendation
          this.detail.notes = data.notes
          this.detail.createdAt = this.moment().format('DD/MM/YYYY')
          this.detail.treatments = data.treatments

          this.$bvModal.show('modal-medrec')
        })
    },
    save(id) {
      this.$axios.put(`${api.customers}/${id}/medical-histories`, {
        description: this.detail.description,
        recomendation: this.detail.recomendation,
        notes: this.detail.notes,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success update medical record',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$bvModal.hide('modal-medrec')
        })
        .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
    },
    debounceSearch(query, type) {
      if (query !== '') {
        clearTimeout(this.debounce)
        this.debounce = setTimeout(async () => {
          this.$emit('search', {
            type,
            query,
          })
        }, 500)
      }
    },
    exportExcel() {
      exportExcel('List Customer Medical Record', `${api.customers}/${this.$route.params.id}/medical-histories/export`, this.models)
      this.models = {
        startDate: '',
        treatments: '',
      }
      this.vars.startDate = {
        startDate: null,
        endDate: null,
      }
      this.$bvModal.hide('modal-export-medrec')
    },
    updateDate(value) {
      this.vars.startDate = value
      const { startDate, endDate } = value
      this.models.startDate = `${this.moment(startDate).format('YYYY-MM-DD')},${this.moment(endDate).format('YYYY-MM-DD')}`
    },
  },
}
</script>
