<template>
  <div class="animated fadeIn">
    <b-card class="px-md-2">
      <h3 class="mb-2">
        {{ $route.meta.breadcrumb[1].text }}
      </h3>
      <b-tabs
        fill
        pills
      >
        <b-tab
          v-if="hasPermission('view/agenda/customer')"
          lazy
          title="Agenda"
        >
          <agenda
            :since="since"
            :treatments="treatments"
            @search="setSearchValue"
          />
        </b-tab>
        <b-tab
          v-if="hasPermission('view/product/customer')"
          lazy
          title="Produk"
        >
          <product
            :since="since"
            :products="products"
            @search="setSearchValue"
          />
        </b-tab>
        <b-tab
          v-if="hasPermission('view/invoice/customer')"
          lazy
          title="Invoice"
        >
          <invoice
            :since="since"
            @search="setSearchValue"
          />
        </b-tab>
        <b-tab
          v-if="hasPermission('view/medical-record/customer')"
          lazy
          title="Medical Record"
        >
          <medical-record
            :treatments="treatments"
            @search="setSearchValue"
          />
        </b-tab>
        <b-tab
          v-if="hasPermission('view/detail-info/customer')"
          lazy
          title="Detail Info"
        >
          <detail />
        </b-tab>
      </b-tabs>
      <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-end">
        <b-button
          type="button"
          variant="light"
          class="btn-min-width rounded mx-md-1 my-1 my-md-0"
          @click="$router.push({path: config.uri})"
        >
          Kembali
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTabs, BTab, BButton,
} from 'bootstrap-vue'
import api from '@/utils/api'
import {
  Agenda, Product, Invoice, MedicalRecord, Detail,
} from './index'

export default {
  name: 'DetailCustomer',
  metaInfo: {
    title: 'Detail Customer',
  },
  components: {
    BCard,
    BTabs,
    BTab,
    BButton,

    Agenda,
    Product,
    Invoice,
    MedicalRecord,
    Detail,
  },
  data() {
    return {
      config: {
        uri: `/${this.$route.meta.link}`,
      },
      masters: {
        regencies: [],
        treatments: [],
        products: [],
      },
    }
  },
  computed: {
    since() {
      return this.masters.regencies
    },
    treatments() {
      return this.masters.treatments
    },
    products() {
      return this.masters.products
    },
  },
  created() {
    this.getListOption()
  },
  methods: {
    async getListOption() {
      const getRegencies = this.$axios.get(api.list_regency)
      const getTreatments = this.$axios.get(api.list_treatments)
      const getProducts = this.$axios.get(api.list_products)
      Promise.all([getRegencies, getTreatments, getProducts]).then(res => {
        this.masters.regencies = [...res[0].data.data.rows]
        this.masters.treatments = [...res[1].data.data]
        this.masters.products = [...res[2].data.data]
      })
    },

    async setSearchValue(payload) {
      const { type, query } = payload
      const response = await this.handleSearchOption(type, query)
      this.masters[type] = [...response]
    },

    async handleSearchOption(type, query) {
      let list

      if (type === 'regencies') {
        list = api.list_regency
      } else if (type === 'treatments') {
        list = api.list_treatments
      } else {
        list = api.list_products
      }

      const res = await this.$axios.get(`${list}?search=${query}`)

      if (type === 'regencies') {
        return res.data.data.rows
      }
      return res.data.data
    },
  },
}
</script>

<style>

</style>
