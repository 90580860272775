<template>
  <validation-observer ref="formCustomers">
    <form>
      <div class="animated fadeIn">
        <b-card class="px-md-2">
          <ResponseAlert ref="response" />
          <h3>{{ $route.meta.breadcrumb[1].text }}</h3>
          <b-row class="mt-2">
            <b-col
              sm="3"
              class="mb-2"
            >
              <div class="image-input">
                <validation-provider
                  #default="{ errors }"
                  name="Photo"
                  :rules="vars.rulesImage"
                >
                  <b-img
                    :src="getUrlOfFile(vars.photo)"
                    class="mb-2"
                  />
                  <label
                    v-if="!isDetailPage"
                    for="file-input"
                  >
                    <input
                      id="file-input"
                      type="file"
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                      hidden
                      @change="AddPhoto"
                    >
                    <feather-icon icon="PlusIcon" />
                    Upload Photo
                  </label>
                  <input
                    v-model="vars.photo"
                    type="hidden"
                    :state="errors.length > 0 ? false:null"
                  >
                  <small class="text-danger text-center d-block m-auto">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-col>
            <b-col
              md="9"
            >
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="2">
                  <label
                    class="h5"
                    for="name"
                  >Member ID</label>
                </b-col>
                <b-col sm="9">
                  <b-form-input
                    id="Name"
                    v-model="vars.memberId"
                    type="text"
                    disabled
                  />
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="2">
                  <label
                    class="h5"
                    for="name"
                  >Nama</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="Nama"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="models.name"
                      :disabled="isDetailPage"
                      type="text"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="2">
                  <label
                    class="h5"
                    for="email"
                  >Email</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="models.email"
                      :disabled="isDetailPage"
                      type="email"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="2">
                  <label
                    class="h5"
                    for="phone_number"
                  >No Telepon</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="No. telepon"
                    rules="required|numeric"
                  >
                    <b-form-input
                      id="phone_number"
                      v-model="models.phone"
                      :disabled="isDetailPage"
                      type="tel"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              md="9"
            >
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="bod"
                  >Tanggal Lahir</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Tanggal lahir"
                    rules="required"
                  >
                    <b-form-datepicker
                      id="bod"
                      v-model="models.bod"
                      show-decade-nav
                      :max="vars.maxDate"
                      :disabled="isDetailPage"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="name"
                  >Jenis Kelamin</label>
                </b-col>
                <b-col sm="8">
                  <div class="d-flex justify-content-between">
                    <b-form-input
                      id="gender"
                      v-model="models.gender"
                      :disabled="isDetailPage"
                      type="text"
                    />
                    <!-- <label class="radio-inline">
                      <input
                        v-model="models.gender"
                        type="radio"
                        value="perempuan"
                        name="gender"
                      >
                      <span>Perempuan</span>
                    </label>
                    <label class="radio-inline">
                      <input
                        v-model="models.gender"
                        type="radio"
                        value="laki"
                        name="gender"
                      >
                      <span>Laki-Laki</span>
                    </label> -->
                  </div>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="address"
                  >Alamat</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Alamat"
                    rules="required"
                  >
                    <b-form-textarea
                      id="address"
                      v-model="models.address"
                      :disabled="isDetailPage"
                      :state="errors.length > 0 ? false:null"
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="kfs"
                  >KFS</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="KFS"
                    rules="required"
                  >
                    <b-form-select
                      id="kfs"
                      v-model="models.kfsId"
                      :disabled="isDetailPage"
                      :state="errors.length > 0 ? false:null"
                      :options="masters.kfs"
                      value-field="id"
                      text-field="name"
                      class="w-100"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="tag"
                  >Tags</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Tag"
                  >
                    <b-form-input
                      id="tag"
                      v-model="models.since"
                      :disabled="isDetailPage"
                      type="text"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="note"
                  >Catatan</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Catatan"
                  >
                    <b-form-textarea
                      id="note"
                      v-model="models.note"
                      :disabled="isDetailPage"
                      :state="errors.length > 0 ? false:null"
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import {
  BCard, BRow, BCol, BFormSelect, BImg, BFormInput, BFormDatepicker, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import api from '@/utils/api'

// eslint-disable-next-line import/no-cycle
import { getUrlOfFile } from '@/utils/helpers'

export default {
  name: 'FormCustomers',
  metaInfo: {
    title: 'Form Customers',
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormSelect,
    BFormTextarea,
    BFormDatepicker,
    BImg,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      config: {
        uri: `/${this.$route.meta.link}`,
      },
      models: {
        kfsId: '',
        name: '',
        photo: '',
        gender: 'perempuan',
        bod: '',
        phone: '',
        email: '',
        address: '',
        note: '',
        since: '',
      },
      vars: {
        photo: null,
        memberId: '',
      },
      masters: {
        regencies: [],
        kfs: [
          {
            id: '',
            name: '- Pilih KFS -',
          },
        ],
      },
      // Functions
      getUrlOfFile,
    }
  },
  computed: {
    isDetailPage() {
      return this.$route.name === 'detail-customer'
    },
  },
  created() {
    this.getById()
    this.getOptions()
  },
  methods: {
    getOptions() {
      this.$axios.get(api.list_kfs).then(res => {
        this.masters.kfs = [...this.masters.kfs, ...res.data.data]
      })
    },
    getById() {
      if (this.$route.params.id) {
        this.$axios.get(`${api.customers}/${this.$route.params.id}/profile`)
          .then(res => {
            this.vars.rulesImage = 'required'

            const { data } = res.data
            this.models.kfsId = data.kfsId
            this.models.name = data.profile.fullName
            this.models.gender = data.profile.gender === 'perempuan' ? 'Perempuan' : 'Laki-Laki'
            this.models.bod = data.profile.bod
            this.models.phone = data.profile.phone
            this.models.email = data.email
            this.models.address = data.profile.address
            this.models.note = data.profile.note
            this.models.since = data.profile.since

            this.vars.photo = data.profile.photo
            this.vars.memberId = data.uniqueId
          }).catch(() => this.$router.push({
            name: 'page404',
          }))
      }
    },
  },
}
</script>

<style>

</style>
